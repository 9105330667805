export function startMonitorActivity() {
  var time;
  window.onload = resetTimer;
  // DOM Events
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(() => {
      window.sessionStorage.setItem("vue-compilation-version-0003453", null);
      window.location.reload();
    }, 1800000);
    // 30 minutos
  }
}
