<template>
  <div class="psico-container">
    <div v-show="step == 1" class="padre">
      <div class="bar">
        <i class="bx bx-category"></i>
        <select class="filter" v-model="filtrado">
          <option class="btn-filtrar" value="pendiente">Pendientes</option>
          <option class="btn-filtrar" value="asignada">Asignadas</option>
          <option class="btn-filtrar" value="archivada">Archivadas</option>
        </select>
      </div>
      <div
        v-show="item.estado == filtrado && solicitudes.length > 0 && step == 1"
        v-for="item in solicitudes"
        :key="item._id"
        class="solicitud"
      >
        <div class="a">
          <div class="name-container" @click="togglear(item._id)">
            <label class="nombre">{{ item.nombreUsuario }}</label>
            <span class="plane-info2">{{ `${item.tipoPersona}` }}</span>
          </div>

          <div v-show="item.toggle" class="item-generic">
            <span>Motivo: </span>
            <label>{{ item.motivoSolicitud }}</label>
          </div>

          <div v-show="item.toggle" class="item-icon">
            <i class="bx bx-envelope nohover"></i>
            <label>{{ item.correoElectronico }}</label>
          </div>
          <div
            v-show="item.toggle"
            id="row-telefono"
            class="item-icon"
            @click="toW(item.numeroTelefono)"
          >
            <i class="bx bxl-whatsapp bx-tada"></i>
            <label>{{ item.numeroTelefono }}</label>
          </div>
          <div v-show="item.toggle" class="item-generic">
            <span>P. Solicitado: </span>
            <label>{{ item.profesionalSolicitado }}</label>
          </div>
          <div v-show="item.toggle" class="item-generic">
            <span>P. Asignado: </span>
            <label>{{ item.datosCita.psicologo }}</label>
          </div>
          <div
            v-show="item.toggle && item.tipoAtencion != 'Solicitud'"
            class="item-generic"
          >
            <span>Remitido por: </span>
            <label>{{ item.remitidoPor }}</label>
          </div>

          <div
            v-show="
              (item.estado == 'asignada' &&
                item.datosCita.fechaCita != 'yyyy-MM-dd' &&
                item.datosCita.horaCita != 'HH:mm') ||
              item.estado == 'archivada'
            "
            class="item-generic"
          >
            <span>Cita:</span>
            <label>{{
              `${item.datosCita.fechaCita};    ${item.datosCita.horaCita}`
            }}</label>
          </div>
          <div
            v-show="item.toggle && item.estado == 'archivada'"
            class="item-generic"
          >
            <span>Asistio:</span>
            <label>{{ `${item.datosCita.asistio}` }}</label>
          </div>
        </div>
        <div class="b">
          <div class="info-plane">
            <span class="plane-info">{{ item.tipoAtencion }}</span>

            <span
              v-if="item.estado == 'pendiente'"
              class="plane-info bx-flashing pendiente"
              >{{ item.estado }}</span
            >

            <span
              v-if="item.estado == 'asignada'"
              class="plane-info asignada"
              >{{ item.estado }}</span
            >

            <span v-if="item.estado == 'archivada'" class="plane-info">{{
              item.estado
            }}</span>
          </div>

          <div v-show="item.toggle" class="acciones">
            <div
              v-show="
                !item.remision &&
                $store.getters.isPsicologo &&
                item.estado != 'archivada'
              "
              @click="toggleAsignacion(item._id)"
              class="accion"
            >
              <i class="bx bxs-edit bx-tada-hover bx-md"></i>
            </div>
            <div
              v-show="
                (item.datosCita.asistio &&
                  !item.asignacion &&
                  !item.remision &&
                  $store.getters.isPsicologo) ||
                item.estado == 'archivada'
              "
              class="accion"
              @click="loadHistory(item)"
            >
              <i class="bx bx-clipboard bx-md"></i>
            </div>

            <div
              v-show="
                (((item.estado == 'pendiente' &&
                  item.datosCita.fechaCita == 'yyyy-MM-dd' &&
                  item.datosCita.horaCita == 'HH:mm') ||
                  (item.estado == 'asignada' &&
                    item.datosCita.fechaCita != 'yyyy-MM-dd' &&
                    item.datosCita.horaCita != 'HH:mm' &&
                    item.datosCita.asistio == true)) &&
                  !item.asignacion) ||
                (item.estado == 'archivada' && $store.getters.isPsicologo)
              "
              @click="item.remision = !item.remision"
              class="accion"
            >
              <i class="bx bx-share bx-flip-horizontal bx-md"></i>
            </div>
          </div>
        </div>
        <div v-show="item.asignacion && item.toggle" class="c">
          <div class="date-content">
            <div class="fechadiv">
              <label for="">Fecha:</label
              ><input
                v-model="item.datosCita.fechaCita"
                class="fecha"
                type="date"
              />
            </div>
            <div class="horadiv">
              <label>Hora:</label>
              <input
                v-model="item.datosCita.horaCita"
                class="hora"
                type="time"
              />
            </div>
            <span @click="limpiarFecha(item._id)">limpiar</span>
          </div>
          <div
            v-show="
              item.estado == 'asignada' &&
              item.datosCita.horaCita != 'HH:mm' &&
              item.datosCita.fechaCita != 'yyyy-MM-dd'
            "
            class="asistio-content"
          >
            <span>Asistio: </span>
            <select v-model="item.datosCita.asistio">
              <option :value="true">SI</option>
              <option :value="false">NO</option>
            </select>
          </div>

          <div @click="guardarCita(item._id)">
            <button class="btn-save-cita">Guardar</button>
          </div>

          <div class="archivar-content">
            <span @click="archivar(item._id)">archivar</span>
            <i class="bx bx-archive"></i>
          </div>
        </div>

        <div v-show="item.remision" class="d">
          <div
            v-show="
              item.datosCita.horaCita == 'HH:mm' ||
              item.datosCita.fechaCita == 'yyyy-MM-dd'
            "
            class="item-remision"
            @click="loadRemisionInterna(item)"
          >
            Remision Interna
          </div>
          <div
            v-show="
              (item.datosCita.horaCita != 'HH:mm' &&
                item.datosCita.fechaCita != 'yyyy-MM-dd' &&
                item.datosCita.asistio == true &&
                $store.getters.isPsicologo) ||
              (item.estado == 'archivada' && $store.getters.isPsicologo)
            "
            class="item-remision"
            @click="loadRemisionExterna(item)"
          >
            Remision Externa
          </div>
        </div>
      </div>
      <div
        v-show="
          (filtrado == 'pendiente' && totalPendientes == 0) ||
          (filtrado == 'asignada' && totalAsignadas == 0) ||
          (filtrado == 'archivada' && totalArchivadas == 0)
        "
        style="display: flex; width: 100%; justify-content: center"
      >
        Nada por aqui.
      </div>
    </div>

    <div v-show="step == 2" class="historia-clinica">
      <div class="datos-historia">
        <h3 class="titulo-historia">Orientación de Caso</h3>

        <label for="datos-basicos" @click="ocultarMostrar('datos-basicos')"
          ><i class="bx bxs-user-detail"></i>Datos Basicos del Paciente</label
        >
        <div v-show="show.datosBasicos" class="contenido" id="datos-basicos">
          <div class="option-item">
            <h4 class="option-item-label">Tipo de persona:</h4>
            <input
              class="option-item-entrada"
              disabled
              v-model="datosOrientacion.tipoPersona"
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Nombre completo:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosOrientacion.nombreCompleto"
              placeholder="escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Sexo:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosOrientacion.sexo"
              placeholder="escriba aqui..."
            />
          </div>

          <div class="option-item">
            <h4 class="option-item-label">Fecha de nacimiento:</h4>
            <input
              class="option-item-entrada"
              type="date"
              v-model="datosOrientacion.fechaNacimiento"
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Edad:</h4>
            <input
              class="option-item-entrada"
              type="number"
              v-model="datosOrientacion.edad"
              placeholder="escriba aqui..."
            />
          </div>

          <div class="option-item">
            <h4 class="option-item-label">Número de identificación:</h4>
            <input
              class="option-item-entrada"
              type="number"
              v-model="datosOrientacion.numeroIdentificacion"
              placeholder="escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Número de teléfono:</h4>
            <input
              class="option-item-entrada"
              type="number"
              v-model="datosOrientacion.numeroTelefono"
              placeholder="escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Número de celular:</h4>
            <input
              class="option-item-entrada"
              type="number"
              v-model="datosOrientacion.numeroCelular"
              placeholder="escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Dirección:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosOrientacion.direccion"
              placeholder="escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Correo electrónico:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosOrientacion.correoElectronico"
              placeholder="escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Programa:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosOrientacion.programa"
              placeholder="escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Jornada:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosOrientacion.jornada"
              placeholder="escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Semestre:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosOrientacion.semestre"
              placeholder="escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Codigo:</h4>
            <input
              class="option-item-entrada"
              type="number"
              disabled
              v-model="datosOrientacion.codigo"
              placeholder="escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Estado civil:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosOrientacion.estadoCivil"
              placeholder="escriba aqui..."
            />
          </div>
        </div>

        <label for="motivo-consulta" @click="ocultarMostrar('motivo-consulta')"
          ><i class="bx bx-notepad"></i>I. MOTIVO DE LA CONSULTA</label
        >
        <div
          v-show="show.motivoConsulta"
          class="contenido"
          id="motivo-consulta"
        >
          <textarea
            class="option-item-entrada"
            v-model="datosOrientacion.motivoConsulta"
            placeholder="escriba aqui..."
            rows="5"
          ></textarea>
        </div>

        <label
          for="percepcion-problematica"
          @click="ocultarMostrar('percepcion-problematica')"
          ><i class="bx bx-notepad"></i>II. PERCEPCIÓN DE LA PROBLEMÁTICA E
          HISTORIA DEL PROBLEMA ACTUAL</label
        >
        <div
          v-show="show.percepcionProblematica"
          class="contenido"
          id="percepcion-problematica"
        >
          <textarea
            class="option-item-entrada"
            v-model="datosOrientacion.percepcionProblematica"
            placeholder="escriba aqui..."
            rows="5"
          ></textarea>
        </div>

        <label
          for="historia-familiar"
          @click="ocultarMostrar('historia-familiar')"
          ><i class="bx bx-group"></i>III. HISTORIA FAMILIAR</label
        >
        <div
          v-show="show.historiaFamiliar"
          class="contenido"
          id="historia-familiar"
        >
          <textarea
            class="option-item-entrada"
            v-model="datosOrientacion.historiaFamiliar"
            placeholder="escriba aqui..."
            rows="5"
          ></textarea>
        </div>

        <label for="historia-medica" @click="ocultarMostrar('historia-medica')"
          ><i class="bx bx-group"></i>IV. HISTORIA MÉDICA</label
        >
        <div
          v-show="show.historiaMedica"
          class="contenido"
          id="historia-medica"
        >
          <textarea
            class="option-item-entrada"
            v-model="datosOrientacion.historiaMedica"
            placeholder="escriba aqui..."
            rows="5"
          ></textarea>
        </div>

        <label
          for="historia-personal"
          @click="ocultarMostrar('historia-personal')"
          ><i class="bx bx-user"></i>V. HISTORIA PERSONAL Y AREAS DE
          AJUSTE</label
        >
        <div
          v-show="show.historiaPersonal"
          class="contenido"
          id="historia-personal"
        >
          <div class="option-item option-item-100">
            <h4 class="option-item-label-title">
              1. Área Personal (Patológicos y no patológicos)
            </h4>
            <textarea
              class="option-item-entrada"
              v-model="datosOrientacion.historiaPersonal.areaPersonal"
              placeholder="escriba aqui..."
              rows="5"
            ></textarea>
          </div>

          <div class="option-item option-item-100">
            <h4 class="option-item-label-title">2. Área Académica</h4>

            <div class="d-flex">
              <div class="option-item-right option-item-50">
                <h4 class="option-item-label-subtitle">
                  Descripción del contexto
                </h4>
                <textarea
                  class="option-item-entrada"
                  v-model="
                    datosOrientacion.historiaPersonal.areaAcademica
                      .descripcionContexto
                  "
                  placeholder="escriba aqui..."
                  rows="5"
                ></textarea>
              </div>

              <div class="option-item-left option-item-50">
                <h4 class="option-item-label-subtitle">
                  Comportamientos que se presentan
                </h4>
                <textarea
                  class="option-item-entrada"
                  v-model="
                    datosOrientacion.historiaPersonal.areaAcademica
                      .comportamientosQueSePresentan
                  "
                  placeholder="escriba aqui..."
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="option-item option-item-100">
            <h4 class="option-item-label-title">3. Área Familiar</h4>

            <div class="d-flex">
              <div class="option-item-right option-item-50">
                <h4 class="option-item-label-subtitle">
                  Descripción del contexto
                </h4>
                <textarea
                  class="option-item-entrada"
                  v-model="
                    datosOrientacion.historiaPersonal.areaFamiliar
                      .descripcionContexto
                  "
                  placeholder="escriba aqui..."
                  rows="5"
                ></textarea>
              </div>

              <div class="option-item-left option-item-50">
                <h4 class="option-item-label-subtitle">
                  Comportamientos que se presentan
                </h4>
                <textarea
                  class="option-item-entrada"
                  v-model="
                    datosOrientacion.historiaPersonal.areaFamiliar
                      .comportamientosQueSePresentan
                  "
                  placeholder="escriba aqui..."
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="option-item option-item-100">
            <h4 class="option-item-label-title">
              4. Área Social y Comunitaria
            </h4>

            <div class="d-flex">
              <div class="option-item-right option-item-50">
                <h4 class="option-item-label-subtitle">
                  Descripción del contexto
                </h4>
                <textarea
                  class="option-item-entrada"
                  v-model="
                    datosOrientacion.historiaPersonal.areaSocialComunitaria
                      .descripcionContexto
                  "
                  placeholder="escriba aqui..."
                  rows="5"
                ></textarea>
              </div>

              <div class="option-item-left option-item-50">
                <h4 class="option-item-label-subtitle">
                  Comportamientos que se presentan
                </h4>
                <textarea
                  class="option-item-entrada"
                  v-model="
                    datosOrientacion.historiaPersonal.areaSocialComunitaria
                      .comportamientosQueSePresentan
                  "
                  placeholder="escriba aqui..."
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="option-item option-item-100">
            <h4 class="option-item-label-title">5. Otras</h4>

            <div class="d-flex">
              <div class="option-item-right option-item-50">
                <h4 class="option-item-label-subtitle">Espiritual</h4>
                <textarea
                  class="option-item-entrada"
                  v-model="datosOrientacion.historiaPersonal.otras.espiritual"
                  placeholder="escriba aqui..."
                  rows="5"
                ></textarea>
              </div>

              <div class="option-item-left option-item-50">
                <h4 class="option-item-label-subtitle">Económica/laboral</h4>
                <textarea
                  class="option-item-entrada"
                  v-model="
                    datosOrientacion.historiaPersonal.otras.economicaLaboral
                  "
                  placeholder="escriba aqui..."
                  rows="5"
                ></textarea>
              </div>

              <div class="option-item-left option-item-50">
                <h4 class="option-item-label-subtitle">Sexual</h4>
                <textarea
                  class="option-item-entrada"
                  v-model="datosOrientacion.historiaPersonal.otras.sexual"
                  placeholder="escriba aqui..."
                  rows="5"
                ></textarea>
              </div>

              <div class="option-item-left option-item-50">
                <h4 class="option-item-label-subtitle">Salud/Autocuidado</h4>
                <textarea
                  class="option-item-entrada"
                  v-model="
                    datosOrientacion.historiaPersonal.otras.saludAutocuidado
                  "
                  placeholder="escriba aqui..."
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <label
          for="impresion-diagnostica"
          @click="ocultarMostrar('impresion-diagnostica')"
          ><i class="bx bx-book"></i>VI. EXAMEN MENTAL E IMPRESIÓN
          DIAGNOSTICA</label
        >
        <div
          v-show="show.impresionDiagnostica"
          class="contenido"
          id="impresion-diagnostica"
        >
          <textarea
            class="option-item-entrada"
            v-model="datosOrientacion.impresionDiagnostica"
            placeholder="escriba aqui..."
            rows="5"
          ></textarea>
        </div>

        <label
          for="tratamiento-psicologico"
          @click="ocultarMostrar('tratamiento-psicologico')"
          ><i class="bx bx-edit"></i>VII. PLAN DE TRATAMIENTO PSICOLÓGICO E
          INSTRUMENTOS DE EVALUACIÓN</label
        >
        <div
          v-show="show.planTratamientoPsicologico"
          class="contenido"
          id="tratamiento-psicologico"
        >
          <textarea
            class="option-item-entrada"
            v-model="datosOrientacion.planTratamientoPsicologico"
            placeholder="escriba aqui..."
            rows="5"
          ></textarea>
        </div>

        <label for="segimiento-caso" @click="ocultarMostrar('segimiento-caso')"
          ><i class="bx bx-folder"></i>VIII. SEGUIMIENTO AL CASO</label
        >
        <div
          v-show="show.seguimientoCaso"
          class="contenido"
          id="segimiento-caso"
        >
          <textarea
            class="option-item-entrada"
            v-model="datosOrientacion.seguimientoCaso"
            placeholder="escriba aqui..."
            rows="5"
          ></textarea>
        </div>

        <div class="container-button-content">
          <div class="button-content">
            <button class="btn-exit-history" @click="step = 1">
              <i class="bx bx-arrow-back" style="margin-right: 0.2rem"></i>
              Salir
            </button>
            <button
              v-show="datosOrientacion._id"
              class="btn-exit-history"
              @click="descargarHistoria()"
            >
              <i class="bx bxs-cloud-download"></i>
            </button>
            <button
              v-if="!onlyRead"
              class="btn-save-history"
              @click="actualizarHistoria"
            >
              Guardar <i class="bx bx-save" style="margin-left: 0.2rem"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-show="step == 3 || step == 4" class="remision-caso">
      <div v-show="step == 3" class="remision-interna">
        <h3 class="titulo-salud">Remision de Caso Interna</h3>
        <select
          style="margin-left: 1rem; margin-bottom: auto"
          class="option-item"
          id="remision-interna-psico"
          v-model="psicoARemitir"
        >
          <option value="seleccione" selected hidden>---seleccione---</option>
          <option v-for="psicologo in psicologos" :key="psicologo">
            {{ psicologo }}
          </option>
        </select>
        <div class="button-content">
          <button class="btn-exit-history" @click="step = 1">
            <i class="bx bx-arrow-back bx-sm"></i>Volver
          </button>

          <button
            v-show="itemTemp.estado != 'archivada'"
            class="btn-save-history"
            @click="remitirInterna()"
          >
            Remitir <i class="bx bx-send bx-sm"></i>
          </button>
        </div>
      </div>
      <div v-show="step == 4" class="remision-externa">
        <div class="contenido">
          <h3 class="titulo-salud">Remision Externa</h3>
          <div class="option-item">
            <h4 class="option-item-label">Nombre completo:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.nombreCompleto"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Numero de identificacion:</h4>
            <input
              class="option-item-entrada"
              type="number"
              v-model="datosRemision.numeroIdentificacion"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Sexo:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.sexo"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Edad:</h4>
            <input
              class="option-item-entrada"
              type="number"
              v-model="datosRemision.edad"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Numero de telefono:</h4>
            <input
              class="option-item-entrada"
              type="number"
              v-model="datosRemision.numeroTelefono"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Numero de celular</h4>
            <input
              class="option-item-entrada"
              type="number"
              v-model="datosRemision.numeroCelular"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Direccion:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.direccion"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Correo electronico:</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.correoElectronico"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Estado civil</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.estadoCivil"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Ocupacion</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.ocupacion"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Tipo persona</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.tipoPersona"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Programa</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.programa"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Jornada</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.jornada"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Semestre</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.semestre"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Codigo</h4>
            <input
              class="option-item-entrada"
              type="number"
              v-model="datosRemision.codigo"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Grupo</h4>
            <input
              class="option-item-entrada"
              type="text"
              v-model="datosRemision.grupo"
              placeholder="Escriba aqui..."
            />
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Percepcion de la problematica</h4>
            <textarea
              class="option-item-entrada"
              v-model="datosRemision.percepcionProblematica"
              placeholder="Escriba aqui..."
            ></textarea>
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Motivo de remisión</h4>
            <textarea
              class="option-item-entrada"
              v-model="datosRemision.motivoRemision"
              placeholder="Escriba aqui..."
            ></textarea>
          </div>
          <div class="option-item">
            <h4 class="option-item-label">Observaciones</h4>
            <textarea
              class="option-item-entrada"
              v-model="datosRemision.observaciones"
              placeholder="Escriba aqui..."
            ></textarea>
          </div>
        </div>
        <div class="button-content">
          <button class="btn-exit-history" @click="step = 1">
            <i class="bx bx-arrow-back bx-sm"></i>Volver
          </button>
          <i
            @click="descargarRemision()"
            v-show="datosRemision._id"
            class="bx bxs-cloud-download bx-sm"
          ></i>
          <button
            v-show="itemTemp.estado != 'archivada'"
            class="btn-save-history"
            @click="remitirExterna()"
          >
            Remitir <i class="bx bx-send bx-sm"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
import localToken from "@/logic/localToken.js";
export default {
  name: "ApsicologicaFragment",
  data: function () {
    return {
      solicitudes: [],
      toggle: false,
      filtrado: "pendiente",
      datosOrientacion: {
        tipoPersona: null,
        nombreCompleto: null,
        sexo: null,
        fechaNacimiento: null,
        edad: null,
        numeroIdentificacion: null,
        numeroTelefono: null,
        direccion: null,
        correoElectronico: null,
        numeroCelular: null,
        programa: null,
        jornada: null,
        semestre: null,
        codigo: null,
        estadoCivil: null,
        motivoConsulta: null,
        percepcionProblematica: null,
        historiaFamiliar: null,
        historiaMedica: null,
        historiaPersonal: {
          areaPersonal: null,
          areaAcademica: {
            descripcionContexto: null,
            comportamientosQueSePresentan: null,
          },
          areaFamiliar: {
            descripcionContexto: null,
            comportamientosQueSePresentan: null,
          },
          areaSocialComunitaria: {
            descripcionContexto: null,
            comportamientosQueSePresentan: null,
          },
          otras: {
            espiritual: null,
            economicaLaboral: null,
            sexual: null,
            saludAutocuidado: null,
          },
        },
        impresionDiagnostica: null,
        planTratamientoPsicologico: null,
        seguimientoCaso: null,
      },
      totalPendientes: 0,
      totalAsignadas: 0,
      totalArchivadas: 0,
      step: 1,
      show: {
        datosBasicos: false,
        motivoConsulta: false,
        percepcionProblematica: false,
        historiaFamiliar: false,
        historiaMedica: false,
        historiaPersonal: false,
        impresionDiagnostica: false,
        planTratamientoPsicologico: false,
        seguimientoCaso: false,
      },
      onlyRead: false,
      psicologos: [],
      psicoARemitir: "seleccione",
      itemTemp: {},
      datosRemision: {
        nombreCompleto: null,
        sexo: null,
        numeroIdentificacion: null,
        edad: null,
        numeroTelefono: null,
        direccion: null,
        correoElectronico: null,
        numeroCelular: null,
        estadoCivil: null,
        ocupacion: null,
        tipoPersona: null,
        programa: null,
        jornada: null,
        semestre: null,
        codigo: null,
        grupo: null,
        percepcionProblematica: null,
        motivoRemision: null,
        observaciones: null,
        remitidoPor: null,
        idSolicitud: null,
      },
    };
  },

  methods: {
    limpiarCamposRemision() {
      this.datosRemision = {
        nombreCompleto: null,
        sexo: null,
        numeroIdentificacion: null,
        edad: null,
        numeroTelefono: null,
        direccion: null,
        correoElectronico: null,
        numeroCelular: null,
        estadoCivil: null,
        ocupacion: null,
        tipoPersona: null,
        programa: null,
        jornada: null,
        semestre: null,
        codigo: null,
        grupo: null,
        percepcionProblematica: null,
        motivoRemision: null,
        observaciones: null,
        remitidoPor: null,
        idSolicitud: null,
      };
    },
    limpiarCamposHistoria() {
      this.datosOrientacion = {
        tipoPersona: null,
        nombreCompleto: null,
        sexo: null,
        fechaNacimiento: null,
        edad: null,
        numeroIdentificacion: null,
        numeroTelefono: null,
        direccion: null,
        correoElectronico: null,
        numeroCelular: null,
        programa: null,
        jornada: null,
        semestre: null,
        codigo: null,
        estadoCivil: null,
        motivoConsulta: null,
        percepcionProblematica: null,
        historiaFamiliar: null,
        historiaMedica: null,
        historiaPersonal: {
          areaPersonal: null,
          areaAcademica: {
            descripcionContexto: null,
            comportamientosQueSePresentan: null,
          },
          areaFamiliar: {
            descripcionContexto: null,
            comportamientosQueSePresentan: null,
          },
          areaSocialComunitaria: {
            descripcionContexto: null,
            comportamientosQueSePresentan: null,
          },
          otras: {
            espiritual: null,
            economicaLaboral: null,
            sexual: null,
            saludAutocuidado: null,
          },
        },
        impresionDiagnostica: null,
        planTratamientoPsicologico: null,
        seguimientoCaso: null,
      };
    },
    async descargarRemision() {
      try {
        await ApiUsers.descargarRAP(
          this.datosRemision._id,
          localToken.getToken()
        );
      } catch (error) {
        console.error(error);
      }
    },
    async descargarHistoria() {
      try {
        await ApiUsers.descargarHAP(
          this.datosOrientacion._id,
          localToken.getToken()
        );
      } catch (error) {
        console.error(error);
      }
    },
    async remitirExterna() {
      try {
        if (
          this.datosRemision.nombreCompleto != null &&
          this.datosRemision.sexo != null &&
          this.datosRemision.numeroIdentificacion != null &&
          this.datosRemision.edad != null &&
          this.datosRemision.numeroTelefono != null &&
          this.datosRemision.direccion != null &&
          this.datosRemision.correoElectronico != null &&
          this.datosRemision.numeroCelular != null &&
          this.datosRemision.estadoCivil != null &&
          this.datosRemision.ocupacion != null &&
          this.datosRemision.tipoPersona != null &&
          this.datosRemision.programa != null &&
          this.datosRemision.jornada != null &&
          this.datosRemision.semestre != null &&
          this.datosRemision.codigo != null &&
          this.datosRemision.grupo != null &&
          this.datosRemision.percepcionProblematica != null &&
          this.datosRemision.motivoRemision != null &&
          this.datosRemision.observaciones != null
        ) {
          this.datosRemision.token = localToken.getToken();
          console.log(this.itemTemp);
          if (this.datosRemision.idSolicitud == null) {
            this.datosRemision.remitidoPor = this.itemTemp.datosCita.psicologo;
            this.datosRemision.idSolicitud = this.itemTemp._id;
            const c = await ApiUsers.createRemisionExterna(this.datosRemision);
            this.resetLanzadorRemitir();
            alert(c.data);
          } else {
            const u = await ApiUsers.updateRemisionExterna(this.datosRemision);
            alert(u.data);
          }

          this.step = 1;
          this.limpiarCamposRemision();
        } else {
          alert("todos los campos son requeridos");
        }
      } catch (error) {
        console.error(error);
      }
    },
    resetLanzadorRemitir() {
      const ax = this.solicitudes.filter((i) => i._id == this.itemTemp._id);
      ax[0].remision = false;
    },
    async loadRemisionExterna(item) {
      this.itemTemp = item;
      const g = await ApiUsers.getRemisionExternaForIdSolicitud(
        item._id,
        localToken.getToken()
      );
      console.log(g);

      if (g.status != 204) {
        this.datosRemision = g.data[g.data.length - 1];
      }

      this.datosRemision.nombreCompleto = item.nombreUsuario;
      this.datosRemision.numeroIdentificacion = item.identificacion;
      this.datosRemision.numeroCelular = item.numeroTelefono;
      this.datosRemision.correoElectronico = item.correoElectronico;
      this.datosRemision.tipoPersona = item.tipoPersona;
      this.datosRemision.programa = item.programa;
      this.datosRemision.jornada = item.jornada;
      this.datosRemision.semestre = item.semestre;
      this.datosRemision.codigo = item.codigo;
      this.datosRemision.nombreCompleto = item.nombreUsuario;

      this.step = 4;
    },
    async remitirInterna() {
      try {
        if (this.psicoARemitir != "seleccione") {
          this.itemTemp.token = localToken.getToken();
          this.itemTemp.tipoAtencion = "Trans. Interna";
          this.itemTemp.remitidoPor = this.itemTemp.datosCita.psicologo;
          this.itemTemp.datosCita.psicologo = this.psicoARemitir;

          const r = await ApiUsers.putSolicitudesAP(
            this.itemTemp._id,
            this.itemTemp
          );
          alert(r.data);

          let newSolicitudes = this.solicitudes.filter(
            (i) => i._id != this.itemTemp._id
          );
          this.solicitudes = newSolicitudes;

          this.contabilizarEstados();
          this.step = 1;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loadRemisionInterna(item) {
      const psicologosList = await ApiUsers.getPsicologos();
      console.log(item);

      for (const i of psicologosList.data) {
        if (i != item.datosCita.psicologo) {
          this.psicologos.unshift(i);
        }
      }
      this.itemTemp = item;
      this.step = 3;
    },
    limpiarFecha(id) {
      for (const i of this.solicitudes) {
        if (i._id == id) {
          i.datosCita.horaCita = "HH:mm";
          i.datosCita.fechaCita = "yyyy-MM-dd";
          i.datosCita.asistio = false;
          i.estado = "pendiente";

          this.guardarCita(id);
        }
      }
    },
    archivar(_id) {
      for (const i of this.solicitudes) {
        if (i._id == _id) {
          i.estado = "archivada";

          this.guardarCita(_id);
        }
      }
    },
    async guardarCita(id) {
      try {
        let objT = {};
        for (const i of this.solicitudes) {
          if (i._id == id) {
            console.log("solicitud a guardar");
            console.log(i);
            objT.horaCita = i.datosCita.horaCita;
            objT.fechaCita = i.datosCita.fechaCita;
            objT.asistio = i.datosCita.asistio;
            objT.estado = i.estado;
            if (
              i.estado == "pendiente" &&
              objT.fechaCita != "yyyy-MM-dd" &&
              objT.horaCita != "HH:mm"
            ) {
              objT.estado = "asignada";
              i.estado = "asignada";
            }
            i.asignacion = false;
          }
        }
        objT.token = localToken.getToken();

        // console.log(objT);
        const r = await ApiUsers.createCitaPsico(id, objT);
        this.contabilizarEstados();
        alert(r.data);
      } catch (error) {
        console.log(error);
      }
    },
    remitir() {
      alert("d");
    },
    toW(numero) {
      const a = document.createElement("a");
      a.href = `https://api.whatsapp.com/send?phone=57${numero}&text=Hola!`;
      a.click();
    },
    togglear(value) {
      for (const i of this.solicitudes) {
        if (i._id == value) {
          i.toggle = !i.toggle;
        }
      }
    },
    toggleAsignacion(value) {
      for (const i of this.solicitudes) {
        if (i._id == value) {
          i.asignacion = !i.asignacion;
        }
      }
    },
    async cargarSolicitudes() {
      try {
        //obtenemos los datos del usuario logueado
        const funcionario = await ApiUsers.getUserId({
          token: localToken.getToken(),
        });

        if (this.$store.getters.isAdmin) {
          //obtenemos todas las solicitudes
          const r = await ApiUsers.getSolicitudesAP(localToken.getToken());
          console.log(r.data);
          let solicitudes_temp = []; //array vacio a nivel del metodo

          for (const key in r.data) {
            let otherTemp = r.data[key];
            otherTemp.toggle = false;
            otherTemp.asignacion = false;
            otherTemp.remision = false;
            solicitudes_temp.unshift(otherTemp);
          }
          this.solicitudes = solicitudes_temp;
          this.contabilizarEstados();
        } else if (this.$store.getters.isPsicologo) {
          //obtenemos todas las solicitudes
          const r = await ApiUsers.getSolicitudesAP(localToken.getToken());

          let solicitudes_temp = []; //array vacio a nivel del metodo

          for (const key in r.data) {
            if (r.data[key].datosCita.psicologo == funcionario.data.nombre) {
              //insertamos en nuestro array temporal todas solicitudes en las que el nombre del profesional solicitado coincida con el nombre del usuario logueado
              let otherTemp = r.data[key];
              otherTemp.toggle = false;
              otherTemp.asignacion = false;
              otherTemp.remision = false;
              solicitudes_temp.unshift(otherTemp);
            }
          }
          //si no hay las solicitudes almacenadas  localmente
          if (this.solicitudes.length == 0) {
            //nuestro array local es igual a nuestro array temporal
            this.solicitudes = solicitudes_temp;
          } else if (this.solicitudes.length > 0) {
            //si ya hay algunas solicitudes almacenadas localmente
            let match = false;
            if (solicitudes_temp.length > this.solicitudes.length) {
              //si nuestro array temporal es mayor que el array local, signifca que hay nuevas solicitudes
              for (const pos in solicitudes_temp) {
                for (const poS in this.solicitudes) {
                  if (solicitudes_temp[pos]._id == this.solicitudes[poS]._id) {
                    match = true; //si hay match significa que esa solicitud ya existe localmente en memoria y no hay que volverla a agregar al array local
                  }
                }

                if (
                  match == false &&
                  solicitudes_temp[pos].datosCita.psicologo ==
                    funcionario.data.nombre
                ) {
                  //si NO hay match significa que esa solicitud NO existe localmente en memoria y hay que agregarla al array local
                  let otherTemp = solicitudes_temp[pos];
                  otherTemp.toggle = false;
                  otherTemp.asignacion = false;
                  otherTemp.remision = false;
                  this.solicitudes.unshift(otherTemp);
                }
              }
            }
          }
          this.contabilizarEstados();
          //luego de 30 segundos se auto llama el metodo
          setTimeout(() => {
            this.cargarSolicitudes();
          }, 30000);
        }
      } catch (error) {
        if (error.status == 401) {
          console.log(`status 401`);
        } else {
          console.error(`${error.response.status} line 1827`);
        }
      }
    },
    asistencia() {},
    async verHistoria() {
      try {
        const r = await ApiUsers.getOrientacionPsicologica(
          this.datosOrientacion.numeroIdentificacion,
          localToken.getToken()
        );
        console.log(r);
      } catch (error) {
        console.log(error);
      }
    },
    async actualizarHistoria() {
      try {
        let data = this.datosOrientacion;
        data.token = localToken.getToken();
        const r = await ApiUsers.updateOrientacionPsicologica(
          this.itemTemp._id,
          data
        );
        alert(r.data);
        this.step = 1;
        this.limpiarCamposHistoria();
      } catch (error) {
        console.log("Error al actualializar la orientacion de caso");
      }
    },
    ocultarMostrar(value) {
      switch (value) {
        case "datos-basicos":
          this.show.datosBasicos = !this.show.datosBasicos;
          break;
        case "motivo-consulta":
          this.show.motivoConsulta = !this.show.motivoConsulta;
          break;
        case "percepcion-problematica":
          this.show.percepcionProblematica = !this.show.percepcionProblematica;
          break;
        case "historia-familiar":
          this.show.historiaFamiliar = !this.show.historiaFamiliar;
          break;
        case "historia-medica":
          this.show.historiaMedica = !this.show.historiaMedica;
          break;
        case "historia-personal":
          this.show.historiaPersonal = !this.show.historiaPersonal;
          break;
        case "impresion-diagnostica":
          this.show.impresionDiagnostica = !this.show.impresionDiagnostica;
          break;
        case "tratamiento-psicologico":
          this.show.planTratamientoPsicologico =
            !this.show.planTratamientoPsicologico;
          break;
        case "segimiento-caso":
          this.show.seguimientoCaso = !this.show.seguimientoCaso;
          break;
      }
    },
    contabilizarEstados() {
      this.totalPendientes = 0;
      this.totalAsignadas = 0;
      this.totalArchivadas = 0;
      for (const i of this.solicitudes) {
        if (i.estado == "pendiente") {
          this.totalPendientes = this.totalPendientes + 1;
        }
        if (i.estado == "asignada") {
          this.totalAsignadas = this.totalAsignadas + 1;
        }
        if (i.estado == "archivada") {
          this.totalArchivadas = this.totalArchivadas + 1;
        }
      }
    },
    async loadHistory(item) {
      this.step = 2;
      if (item.estado == "archivada") {
        this.onlyRead = true;
      } else {
        this.onlyRead = false;
      }
      try {
        console.log(item);
        this.itemTemp = item;

        const r = await ApiUsers.getOrientacionPsicologica(
          item._id,
          localToken.getToken()
        );
        if (r.status != 204) {
          this.datosOrientacion = r.data;
        }
        this.datosOrientacion.numeroIdentificacion = item.identificacion;
        this.datosOrientacion.codigo = item.codigo;
        this.datosOrientacion.correoElectronico = item.correoElectronico;
        this.datosOrientacion.jornada = item.jornada;
        this.datosOrientacion.numeroCelular = item.numeroTelefono;

        this.datosOrientacion.programa = item.programa;
        this.datosOrientacion.semestre = item.semestre;
        this.datosOrientacion.tipoPersona = item.tipoPersona;
        this.datosOrientacion.nombreCompleto = item.nombreUsuario;
      } catch (error) {
        console.log(error);
        if (error.response) {
          console.log(error.response.data);
        }
      }
    },
    ShowLoadAnimation(valor) {
      if (valor) {
        this.$store.dispatch("setLoadAnimationAction", { s: true });
      } else {
        setTimeout(() => {
          this.$store.dispatch("setLoadAnimationAction", { s: false });
        }, 500);
      }
    },
  },
  mounted() {
    this.ShowLoadAnimation(false);
  },

  created() {
    this.cargarSolicitudes();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap");
* {
  box-sizing: border-box;
  font-family: "Fredoka", sans-serif;
}

.remision-externa {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
.remision-interna {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0.5rem;
}
.remision-caso {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.btn-save-history,
.btn-exit-history {
  border-radius: 5px;

  padding: 0.5rem;
  display: flex;
  width: min-content;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: rgba(132, 247, 161, 0);
}
.btn-save-history i,
.btn-exit-history i {
  color: black;
  padding: 0px !important;
}
.btn-save-history:hover,
.btn-exit-history:hover {
  font-weight: 500;
}

.item-remision {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
}

.item-remision:hover {
  font-weight: 500;
  cursor: pointer;
}
.d {
  margin-top: 0.5rem;
  border-top: 1px solid rgba(128, 128, 128, 0.301);
  display: flex;
  justify-content: space-around;
}
.filter {
  border: none;
  background-color: rgba(240, 248, 255, 0);
  width: min-content;
  color: black;
}
.filter:active,
.filter:hover,
.filter:focus {
  border: none;
  outline: none;
  cursor: pointer;
}
.psico-container {
  display: flex;
  overflow: auto;
  height: 100%;
  width: 100%;
}
.date-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
}
.asistio-content {
  display: flex;
  width: 100%;
  margin-top: 3%;
  margin-bottom: 3%;
}
.asistio-content select {
  width: min-content;
  font-weight: 400;
  font-size: 1rem;
  color: black;
}
.archivar-content {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  border: none;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
}
.archivar-content span:hover {
  cursor: pointer;
}
.archivar-content span {
  margin-right: 0.2rem;
}
.asistio-content span {
  margin-right: 0.3rem;
  font-weight: 500;
}
.padre {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  overflow: auto;
  background-color: rgb(255, 255, 255);
}
.solicitud {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: min-content;
  padding: 0.8rem;
  border-radius: 5px;
  margin: 10px 0px 10px 0px;
  margin-left: 5%;
  margin-right: 5%;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 9px rgba(128, 128, 128, 0.473);
}
.a {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* border: solid red; */
}
.b {
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  /* border: solid blue; */
}
.b div {
  height: 100%;
}
.info-plane {
  display: flex;
  align-items: center;
}
.plane-info {
  font-size: 0.8rem;
  margin: 3px;
  padding: 1px 5px 1px 5px;
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.1);
  border: solid rgba(128, 128, 128, 0.3);
  color: rgba(0, 0, 0, 0.5);
  width: max-content;
}

.plane-info2 {
  text-align: end;
  font-size: 0.8rem;
  margin: 3px;
  padding: 1px 5px 1px 5px;
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0);
  border: none;
  color: rgba(0, 0, 0, 0.5);
}

.name-container {
  display: flex;
  justify-content: space-between;
}
.nombre {
  font-weight: 500;
}
.item-icon {
  display: flex;
  align-items: center;
}
.item-icon i {
  margin-right: 3px;
}
.item-generic {
  display: flex;
  align-items: flex-start;
  height: min-content;
}
.item-generic span {
  margin-right: 3px;
  background-color: rgba(128, 128, 128, 0);
  border: none;
  font-weight: 500;
}
.item-generic label {
  margin: 0;
  padding: 0;
}
.acciones {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  z-index: 99;
}
.nohover:hover {
  color: black;
}
#row-telefono {
  z-index: 99;
}
#row-telefono label:hover {
  cursor: pointer;
}
.accion {
  display: flex;
  justify-content: center;
  align-items: center;
}
.accion:hover {
  cursor: pointer;
}
.asignada {
  background-color: rgba(47, 209, 96, 0.301);
  border-color: rgb(47, 209, 96);
  color: black;
}
.pendiente {
  background-color: rgba(228, 77, 77, 0.637);
  border-color: rgb(228, 77, 77);
  color: black;
}

.c {
  margin-top: 0.5rem;
  border-top: 1px solid rgba(128, 128, 128, 0.205);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c label {
  margin-right: 0.5rem;
}

.fecha {
  width: 9.5rem;
}

.hora {
  width: 9.5rem;
  margin-left: 0.5rem;
}

.btn-save-cita {
  border-radius: 1rem;
  background-color: rgba(170, 235, 235, 0.253);
  border: 1px solid rgb(189, 189, 189);
}
.btn-save-cita:active {
  background-color: rgba(155, 252, 155, 0.233);
  border: 1px solid rgb(20, 20, 20);
}

.fechadiv,
.horadiv {
  margin-bottom: 0.5rem;
  width: 50%;
}
.fechadiv,
.horadiv label {
  font-weight: 500;
}

.checkasistencia {
  transform: scale(1.3);
}

.bar {
  display: flex;
  padding: 15px;
  justify-content: flex-start;
  align-items: center;
}
.btn-filtrar {
  cursor: pointer;
  background-color: rgba(228, 36, 19, 0.1);
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.btn-filtrar:hover {
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.btn-filtrar:active {
  border: solid 1px rgba(0, 0, 0, 0.432);
}

.historia-clinica {
  padding: 1rem;
  width: 100vw;
}

.datos-historia {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titulo-historia {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.datos-historia label {
  cursor: pointer;
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-block-start: solid 1px rgb(194, 191, 197);
}

.datos-historia i {
  padding-right: 0.5rem;
  transform: scale(1.3);
}

.container-button-content {
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 1rem;
}

.button-content {
  border-radius: 3px;
  padding: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #f4fbfe;
  margin-top: 3rem;
  border: 0.1px solid rgba(128, 128, 128, 0.2);
}
</style>
